<template>
  <div
    class="JiansuoPhone"
    v-infinite-scroll="getData"
    infinite-scroll-distance="420"
    infinite-scroll-disabled="busy"
  >
    <div class="top">
      <el-row type="flex" justify="center">
        <el-col :span="22">
          <div class="jiansuoNav">
            <router-link target="_blank" to="/">
              <span>首页</span>
            </router-link>
            <span><a href="http://sscp.cssn.cn/">中国社会科学杂志社</a> </span>
            <span class="line"></span>
            <span><a href="http://www.cass.cn/">中国社会科学院</a></span>
          </div>

          <div class="jiansuo_logo">
            <img src="@/assets/image/zhong_logo.png" alt="" />
          </div>

          <div class="jiansuo_search">
            <el-input
              placeholder="请输入您要搜索的内容"
              v-model="searchInput"
              @keyup.enter.native="changinp(searchInput)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="shipjians(searchInput)"
                class="search_btn"
              ></el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="bottom">
      <el-row type="flex" justify="center">
        <el-col :span="22">
          <div class="jiansuo_content">
            <div class="card" v-for="item in list" :key="item.index">
              <router-link
                target="_blank"
                :to="{
                  path: '/xq',
                  query: {
                    qing: item.id,
                    mian: 'inp_jian',
                    retrieval: searchInput,
                  },
                }"
              >
                <div class="card_img">
                  <img v-lazy="item.pub_cover" alt="" />
                </div>
                <div class="card_text">
                  <p class="title">{{ item.SYS_TOPIC }}</p>
                  <p class="time">{{ $ModuleDate(item.RECORD_TIME) }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="loading">
        <p class="busy" v-show="busy">加载中...</p>
        <p class="finish" v-show="finish">没有更多了</p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import api from "@/api/index.js";
import Footer from "@/components/Footer.vue";
export default {
  name: "",

  components: {
    Footer,
  },

  data() {
    return {
      drawer: false,
      list: [],
      searchInput: "",
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
    };
  },

  created() {
    this.searchInput = this.$store.state.searchInput;
    this.load();
  },
  methods: {
    handleClose(done) {
      done();
    },
    shipjians() {
      this.pageNo = 1;
      this.load();
      this.list = [];
    },
    changinp(value) {
      if (value != "") {
        this.pageNo = 1;
        this.load();
        this.list = [];
      } else {
        this.$message({
          message: "请输入您要搜索的内容",
          type: "warning",
        });
      }
    },
    load() {
      var slef = this;
      if (this.searchInput !== "") {
        if (this.pageNo == 1) {
          slef.getData();
        } else {
          this.busy = true;
          setTimeout(function () {
            slef.getData();
          }, 1000);
        }
      }
    },
    getData() {
      if (this.finish) {
        return;
      }
      this.busy = true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          q: this.searchInput,
          pageNo: this.pageNo,
          pageSize: 12,
        })
        .then((res) => {
          // console.log("res789654123", res.result);
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }

          this.busy = false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
  },
};
</script>

<style lang='scss'>
.JiansuoPhone {
  a {
    color: #212121;
  }
  .top {
    background: url(../../assets/image/saerch-head.jpg);
    padding-bottom: 3.75rem;
    background-size: 100% 90%;
    background-color: #ffffff;
    background-repeat: no-repeat;

    .jiansuoNav {
      margin: 1rem 0;
      span {
        padding: 0 5px;
      }
      span:nth-child(2),
      span:nth-child(3),
      span:nth-child(4) {
        float: right;
      }
      .line::after {
        content: "";
        width: 1px;
        height: 1.25rem;
        display: inline-block;
        background: #999;
      }
    }
    .jiansuo_logo {
      margin-top: 2rem;
      text-align: center;
      img {
        width: 60%;
      }
    }

    .jiansuo_search {
      input {
        outline: none;
        border: none;
      }
      margin-top: 1rem;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75),
        0px 3px 3px 0px rgba(119, 119, 119, 0.55);
      .search_btn {
        background: #794110;
        color: #fff;
        height: 40px;
      }
    }
  }
  .bottom {
    background: #f0f0f0;
    min-height: 60vh;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    .jiansuo_content {
      .card {
        width: 100%;
        padding: 1rem;
        background: #ffffff;
        margin-top: 1rem;
        box-sizing: border-box;
        border-radius: 5px;
        .card_img {
          img {
            width: 100%;
          }
        }
        .card_text {
          .title {
            margin-top: 1rem;
            font-size: 1.2rem;
            font-weight: bold;
            color: #333333;
            line-height: 1.5rem;
            // height: 48px;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .time {
            margin-top: 1rem;
          }
        }
      }
    }
  }
  .loading {
    margin: 0.5rem 0;
    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }

  .Footer {
    border-top: 0.375rem solid #efe3c3;
    background-color: #efe3c3;
  }
}
.jiansuoNav span {
  color: #764602 !important;
}
.jiansuoNav span a {
  color: #764602 !important;
}
.jiansuoNav span a:hover {
  font-weight: bold;
  color: #764602 !important;
  cursor: pointer;
}
</style>