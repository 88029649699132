<template>
  <div>
    <JiansuoPc v-if="this.$store.state.isEquipment == 'phone' ? false : true"/>
    <JiansuoPhone v-if="this.$store.state.isEquipment == 'phone' ? true : false"/>
  </div>
</template>

<script>
import JiansuoPc from './Jiansuo_pc.vue'
import JiansuoPhone from './Jiansuo_phone.vue'
export default {
  name: '',

  components: {
    JiansuoPc,
    JiansuoPhone
  },

  data() {
    return {}
  },

  created() {},

  methods: {},

}

</script>

<style lang='scss' scoped>
</style>