<template>
  <div class="jiansuoPC">
    <div>
      <div class="top">
        <div class="mainWidth">
          <div class="jiansuoNav">
            <router-link target="_blank" to="/">
              <span>社科视频首页</span>
            </router-link>
            <span><a href="http://sscp.cssn.cn/">中国社会科学杂志社</a></span>
            <span class="line"></span>
            <span><a href="http://www.cass.cn/">中国社会科学院</a></span>
          </div>

          <div class="jiansuo_logo">
            <img src="@/assets/image/zhong_logo.png" alt="" />
          </div>

          <div class="jiansuo_search">
            <el-input
              placeholder="请输入您要搜索的内容"
              v-model="searchInput"
              @keyup.enter.native="changinp(searchInput)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="shipjians(searchInput)"
                class="search_btn"
                >搜索</el-button
              >
            </el-input>
          </div>
        </div>
      </div>

      <div class="bottom mainWidth">
        <el-row type="flex" justify="center">
          <el-col>
            <div class="shaixuan">
              <span class="sort">结果排序：</span>
              <ul class="shaixuan_btn">
                <li
                  v-for="(item, index) in title"
                  :key="index"
                  @click="btnClick(index)"
                  :class="{ active: itemIndex == index }"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="jieguo">
              相关结果 <span>{{ this.totalCount }}</span> 条
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col>
            <div
              class="jiansuo_content"
              v-for="(item, index) in 2"
              :key="index"
              v-show="itemIndex == index"
            >
              <el-row>
                <el-col
                  :xs="22"
                  :sm="6"
                  :md="6"
                  :lg="6"
                  :xl="6"
                  v-for="item in list"
                  :key="item.index"
                >
                  <div class="card">
                    <router-link
                      target="_blank"
                      :to="{
                        path: '/xq',
                        query: {
                          qing: item.id,
                          mian: 'inp_jian',
                          retrieval: searchInput,
                        },
                      }"
                    >
                      <div class="card_img">
                        <img v-lazy="item.pub_cover" alt="" />
                      </div>
                      <div class="card_text">
                        <p class="title">{{ item.SYS_TOPIC }}</p>
                        <p class="time">
                          {{ $ModuleDate(item.RECORD_TIME) }}
                        </p>
                      </div>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 分页 -->
      <div class="paging">
        <div>
          <el-pagination
            layout="total,prev, pager, next, jumper "
            @current-change="handleCurrentChange"
            :page-count="parseInt(this.totalPages)"
            background
            :hide-on-single-page="true"
            :current-page="currentPage"
            :total="parseInt(this.totalCount)"
            :page-size="12"
          ></el-pagination>
        </div>
      </div>
      <!-- 底部 -->
      <navdi />
    </div>
  </div>
</template>
<!-- :total="parseInt(this.totalCount)" -->
<script>
import navdi from "@/components/Footer.vue";
import api from "@/api/index.js";
export default {
  name: "",

  components: {
    navdi,
  },

  data() {
    return {
      title: ["按相关度", "按日期"],
      itemIndex: 0,
      searchInput: "",
      list: [],
      totalCount: "",
      totalPages: "",
      currentPage: 1,
    };
  },

  created() {
    this.searchInput = this.$route.query.inp_value;
    this.createData(this.$route.query.inp_value);
  },
  watch: {
    currentPage: {
      handler: function () {},
      deep: true,
      immediate: true,
    },
  },

  methods: {
    btnClick(index) {
      this.itemIndex = index;
      if (index == 0) {
        //按相关度
        api
          .post("spc/cms/publish/queryList.do", {
            s: 2,
            q: this.searchInput,
            pageNo: 1,
            pageSize: 12,
          })
          .then((res) => {
            this.list = res.result;
            this.currentPage = 1;
          });
      } else if (index == 1) {
        //按日期
        api
          .post("spc/cms/publish/queryList.do", {
            s: 2,
            q: this.searchInput,
            pageNo: 1,
            pageSize: 12,
            sorts: ["pub_lastmodified desc"],
          })
          .then((res) => {
            this.list = res.result;
            this.currentPage = 1;
          });
      }
    },
    shipjians(searchInput) {
      //搜索按钮
      if (searchInput == "") {
        this.$message({
          message: "请输入您要搜索的内容",
          type: "warning",
        });
      } else {
        this.itemIndex = 0;
        this.searchInput = searchInput;
        this.createData(this.searchInput);
      }
    },
    changinp(searchInput) {
      //回车键
      if (searchInput == "") {
        this.$message({
          message: "请输入您要搜索的内容",
          type: "warning",
        });
      } else {
        this.itemIndex = 0;
        this.searchInput = searchInput;
        this.createData(this.searchInput);
      }
    },
    createData(value) {
      //value检索框里的值
      if (value !== "") {
        api
          .post("spc/cms/publish/queryList.do", {
            s: 2,
            q: value,
            pageNo: 1,
            pageSize: 12,
          })
          .then((res) => {
            this.totalCount = res.totalCount;
            this.totalPages = res.totalPages;
            if (res.result.length !== 0) {
              this.list = res.result;
              this.currentPage = 1;
            } else {
              this.$message({
                message: "暂无数据",
                type: "warning",
              });
            }
          });
      }
    },
    handleCurrentChange(val) {
      //改变当前页时触发 val:页
      this.currentPage = val;
      this.getData(val);
      document.body.scrollTop = 500;
      document.documentElement.scrollTop = 500;
    },
    getData(val) {
      //点击切换页
      if (this.itemIndex == 1) {
        //如果是按时间排序时
        api
          .post("spc/cms/publish/queryList.do", {
            s: 2,
            q: this.searchInput,
            pageNo: val,
            pageSize: 12,
            sorts: ["pub_lastmodified desc"],
          })
          .then((res) => {
            this.list = res.result;
          });
      } else if (this.itemIndex == 0) {
        //如果是按相关排序时
        api
          .post("spc/cms/publish/queryList.do", {
            s: 2,
            q: this.searchInput,
            pageNo: val,
            pageSize: 12,
          })
          .then((res) => {
            this.list = res.result;
          });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.jiansuoPC {
  a {
    color: #212121;
  }

  .top {
    background: url(../../assets/image/banner-back.png);
    // padding-bottom: 5rem;
    height: 30.625rem;
    background-size: 100% 90%;
    background-color: #ffffff;
    background-repeat: no-repeat;

    .jiansuoNav {
      padding: 1rem 0;

      span {
        padding: 0 0.3125rem;
      }

      span:nth-child(2),
      span:nth-child(3),
      span:nth-child(4) {
        float: right;
      }

      .line::after {
        content: "";
        width: 1px;
        height: 1.25rem;
        display: inline-block;
        background: #999;
      }
    }

    .jiansuo_logo {
      margin-top: 3rem;
      text-align: center;

      img {
        width: 40%;
      }
    }

    .jiansuo_search {
      .el-input {
        input {
          border: none !important;
          outline: none !important;
        }
      }

      width: 60%;
      margin: 1rem auto;
      border: none;

      .search_btn {
        background: #794110;
        color: #fff;
        height: calc(3.5rem - 2px);
        font-size: 1.3rem;
      }

      /deep/.el-input__inner {
        height: 3.5rem;
      }
    }
  }

  .bottom {
    min-height: 60vh;
    padding-top: 0.5rem;
    // padding-bottom: 1rem;

    .shaixuan {
      float: left;

      .sort {
        float: left;
        line-height: 2.3rem;
      }

      .shaixuan_btn {
        float: left;

        li {
          display: inline-block;
          padding: 0.4375rem 0.875rem;
          font-size: 1rem;
          font-weight: 400;
          color: #333333;
          margin-right: 1rem;
          cursor: pointer;
        }
      }

      .active {
        background: #d1ac7a;
        border-radius: 3.125rem;
        color: #ffffff !important;
      }
    }

    .jieguo {
      float: right;

      span {
        color: #bd1a2d;
      }
    }

    .jiansuo_content {
      .card {
        width: 100%;
        padding: 0 1rem;
        background: #ffffff;
        margin-top: 1rem;
        box-sizing: border-box;
        border-radius: 5px;


        .card_img {
          img {
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            height: 239px;
          }

          @media screen and(max-width:1440px) {
            img {
              height: 167px;
            }
          }
        }

        .card_text {
          .title {
            margin-top: 1rem;
            font-size: 1.2rem;
            font-weight: bold;
            color: #333333;
            line-height: 1.5rem;
            height: 48px;
            // height: 48px;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .time {
            margin-top: 0.2rem;
          }
        }
      }
    }
  }

  .paging {
    text-align: center;
    margin: 1rem 0;

    .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      display: inline-block;
      font-size: 1rem;
      min-width: 2.25rem;
      height: 1.75rem;
      line-height: 1.75rem;
      vertical-align: top;
      box-sizing: border-box;
    }

    .el-pagination .btn-next .el-icon,
    .el-pagination .btn-prev .el-icon {
      display: block;
      font-size: 1rem;
      font-weight: 700;
    }

    .el-pager li {
      padding: 0 0.25rem;
      background: #fff;
      font-size: 0.8125rem;
      min-width: 2.25rem;
      height: 1.75rem;
      line-height: 1.75rem;
      box-sizing: border-box;
      text-align: center;
    }

    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      margin: 0 0.3125rem;
      background-color: #f4f4f5;
      color: #606266;
      min-width: 1.875rem;
      border-radius: 2px;
    }

    .el-pagination__editor.el-input {
      width: 3.125rem;
    }

    .el-pagination__editor.el-input .el-input__inner {
      height: 1.75rem;
      font-size: 1rem;
    }

    .el-pagination__jump .el-input__inner {
      padding: 0 0.1875rem;
    }

    .el-pagination__editor {
      line-height: 1.125rem;
      padding: 0 0.125rem;
      height: 1.75rem;
      text-align: center;
      margin: 0 0.125rem;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }
}

.jiansuoNav span a {
  color: #764602 !important;
}

.jiansuoNav span:hover {
  font-weight: bold;
  color: #764602 !important;
  cursor: pointer;
}
</style>