var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getData),expression:"getData"}],staticClass:"JiansuoPhone",attrs:{"infinite-scroll-distance":"420","infinite-scroll-disabled":"busy"}},[_c('div',{staticClass:"top"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":22}},[_c('div',{staticClass:"jiansuoNav"},[_c('router-link',{attrs:{"target":"_blank","to":"/"}},[_c('span',[_vm._v("首页")])]),_c('span',[_c('a',{attrs:{"href":"http://sscp.cssn.cn/"}},[_vm._v("中国社会科学杂志社")])]),_c('span',{staticClass:"line"}),_c('span',[_c('a',{attrs:{"href":"http://www.cass.cn/"}},[_vm._v("中国社会科学院")])])],1),_c('div',{staticClass:"jiansuo_logo"},[_c('img',{attrs:{"src":require("@/assets/image/zhong_logo.png"),"alt":""}})]),_c('div',{staticClass:"jiansuo_search"},[_c('el-input',{attrs:{"placeholder":"请输入您要搜索的内容"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changinp(_vm.searchInput)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}},[_c('el-button',{staticClass:"search_btn",attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":function($event){return _vm.shipjians(_vm.searchInput)}},slot:"append"})],1)],1)])],1)],1),_c('div',{staticClass:"bottom"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":22}},[_c('div',{staticClass:"jiansuo_content"},_vm._l((_vm.list),function(item){return _c('div',{key:item.index,staticClass:"card"},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/xq',
                query: {
                  qing: item.id,
                  mian: 'inp_jian',
                  retrieval: _vm.searchInput,
                },
              }}},[_c('div',{staticClass:"card_img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],attrs:{"alt":""}})]),_c('div',{staticClass:"card_text"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.$ModuleDate(item.RECORD_TIME)))])])])],1)}),0)])],1),_c('div',{staticClass:"loading"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.busy),expression:"busy"}],staticClass:"busy"},[_vm._v("加载中...")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.finish),expression:"finish"}],staticClass:"finish"},[_vm._v("没有更多了")])])],1),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }